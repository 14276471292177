<template>
    <el-dialog
        :title="dialogueTit"
        :visible.sync="showDialog"
        width="50%"
        top="5vh"
        :center="true"
        :modal="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :lock-scroll="true"
        @close="closeFn"
        class="choseMembers"
    >
        <div class="search">
            <!-- <el-input placeholder="请输入搜索条件" v-model="searchName" @keyup.enter.native="handleSearch"></el-input> -->
            <el-autocomplete
                class="inline-input"
                v-model="searchName"
                placeholder="请输入内容"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                @select="handleSelect"
            >
            </el-autocomplete>
        </div>
        <div class="clearfix createTeamCon">
            <div class="orgTree fl">
                <div class="tit">
                    组织结构
                </div>
                <div>
                    <el-tree
                        :props="defaultProps"
                        node-key="treeid"
                        @node-click="handleNodeClick"
                        :load="loadTreeNodes"
                        :expand-on-click-node="true"
                        lazy
                    ></el-tree>
                </div>
            </div>
            <div class="transContainer fl">
                <div class="trans trans-left">
                    <div class="tit">
                        <el-checkbox v-model="checkWaitAll" @change="handleWaitAllChange">
                            待选人员
                        </el-checkbox>
                    </div>
                    <!-- :disabled="item.accid==''?true:false" -->
                    <div class="trans-con">
                        <el-checkbox-group v-model="checkedWaitMembers" @change="handleCheckedWaitChange">
                            <template v-for="item in waitMembersData">
                                <!-- <el-checkbox
                                    :label="item"
                                    :key="item.User_Id"
                                    :disabled="!item.accid"
                                    v-if="!item.accid"
                                >
                                    {{ item.User_Name }}(未注册)
                                </el-checkbox> -->
                                <el-checkbox
                                    :label="item"
                                    :key="item.User_Id"
                                    :disabled="item.isExist"
                                    v-if="item.isExist"
                                >
                                    {{ item.User_Name }}(群成员已存在)
                                </el-checkbox>
                                <el-checkbox
                                    :label="item"
                                    :key="item.User_Id"
                                    :disabled="item.disable || !item.Contacts_AccId"
                                    v-else
                                >
                                    {{ item.User_Name }}
                                </el-checkbox>
                            </template>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="fl trans-arrow">
                    <div class="buttons">
                        <span class="button-item" @click="delChosed">
                            <i class="iconfont iconarrow2_left"></i>
                        </span>
                        <span class="button-item" @click="transRight">
                            <i class="iconfont iconarrow2_right"></i>
                        </span>
                    </div>
                </div>
                <div class="trans trans-right">
                    <div class="tit">
                        <el-checkbox v-model="checkChosedAll" @change="handleChosedAllChange">
                            已选人员
                        </el-checkbox>
                        <!-- <el-button
                            class="delbtn"
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            @click="delChosed"
                        ></el-button> -->
                    </div>
                    <div class="trans-con">
                        <el-checkbox-group v-model="checkedChosedMembers" @change="handleCheckedChosedChange">
                            <el-checkbox v-for="item in chosedMembersData" :label="item" :key="item.User_Id">
                                {{ item.User_Name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <!-- <el-transfer v-model="selectedMembers" :data="membersData"></el-transfer> -->
            </div>
        </div>
        <!-- <div class="block transPagination">
            <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pagination.size"
                layout="total, prev, pager, next"
                :total="pagination.total"
            >
            </el-pagination>
        </div> -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submitFn">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    components: {},
    props: {
        showDialogue: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: '',
        },
        dialogueTit: {
            type: String,
            default: '',
        },
        members: {
            type: Array,
        },
    },
    data() {
        return {
            orgTreeData: [],
            defaultProps: {
                isLeaf: 'is_leaf',
                children: 'children',
                label: 'name',
            },
            currentIndex: 1,
            pagination: { size: 20, total: 0 },
            waitMembersData: [], // 待选-人员
            checkedWaitMembers: [], // 待选-已选人员
            checkWaitAll: false, // 待选-全选 
            chosedMembersData: [], // 已选-人员
            checkedChosedMembers: [], // 已选-已选人员
            checkChosedAll: false, // 已选-全选 
            orgData: {
                id: '',
                name: '',
            },
            searchName: '', // 回车搜索条件
            searchStr: '',
            treeId: 0,
            serachPageSize: 1000,
        };
    },
    computed: {
        showDialog() {
            return this.showDialogue;
        },
    },
    methods: {
        closeFn() {
            this.$emit('update-show', false);
        },
        handleClose() {
            this.$emit('update-show', false);
        },
        handleCurrentChange(val) {
            this.serachPageSize = 0;
            this.searchStr = '';
            this.currentIndex = val;
            this.getUsersById(null, 'page');

        },
        // 回车搜索事件
        async querySearch(queryString, cb) {
            this.searchStr = this.searchName;
            // this.pagination.size = 10000;
            this.currentIndex = 1;
            this.orgData = {
                id: '',
                name: '',
            };
            const res1 = await this.getUsers();
            const rows = res1.map(v => {
                return { ...v, org_name: this.orgData.name, value: v.user_name };
            });
            cb(rows);
        },
        // 搜索结果添加到已选
        handleSelect(item) {
            const data = this.chosedMembersData.filter(function (v) {
                return v.User_Id === item.User_Id;
            });
            if (data.length > 0) {
                this.$message.warning('已选列表已存在！');
            } else if (!item.Contacts_AccId) {
                this.$message.warning('未注册');
            } else if (item.disable) {
                this.$message.warning('已选联系人');
            } else {
                this.chosedMembersData.push(item);
            }
        },
        /* 组织结构树 */
        loadTreeNodes(node, resolve) {
            if (node.level === 0) {
                this.getOrgTree(this.$takeTokenParameters('DeptId'), resolve);
            } else {
                this.getOrgTree(node.data.id, resolve);
            }
        },
        getOrgTree(id, resolve) {
            const _resolve = resolve;
            this.$axios
                .get('/interfaceApi/basicplatform/depts/tree/list?deptId=' + id)
                .then(res => {
                    if (res) {
                        _resolve(res);
                    }
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 组织结构树点击节点事件
        handleNodeClick(data) {
            this.getUsersById(data, 'tree');
        },
        // 根据组织机构拉取用户ID
        async getUsersById(data, originType) {
            if (originType === 'tree') {
                this.orgData = data;
                this.searchStr = '';
                this.serachPageSize = 0;
            }
            const res = await this.getUsers();
            if (res) {
                const rows = res.map(v => {
                    return { ...v, org_name: this.orgData.name };
                });
                this.checkedWaitMembers = [];
                this.checkWaitAll = false;
                // this.pagination.total = res.total;
                this.chosedMembersData.map(v => {
                    const id = v.User_Id;
                    rows.filter(item => {
                        if (item.User_Id === id) {
                            item.disable = true;
                        }
                    });
                });
                if (this.type === 'addTeamMember' && this.members && this.members[0]) {
                    this.members.map(v => {
                        rows.filter(item => {
                            if (item.Contacts_AccId === v) {
                                item.isExist = true;
                            }
                        });
                    });
                }
                // 排除自己
                // const token = window.sessionStorage.getItem('token');
                // const decode = jwtDecode(token);
                const ownUserId = this.$takeTokenParameters('Uid');
                rows.filter(item => {
                    if (item.User_Id === ownUserId) {
                        item.disable = true;
                    }
                });

                this.waitMembersData = rows;
            }
        },
        // 获取用户
        getUsers() {
            const param = {
                deptId: this.orgData.id,
                search: this.searchStr,
            };
            const qs = require('qs');
            return this.$axios.get('/interfaceApi/message/MessageNetease/all_contacts?' + qs.stringify(param));
        },
        /* 待选列表选中事件 */
        handleWaitAllChange() {
            if (this.checkWaitAll) {
                const arr = [];
                this.waitMembersData.map(item => {
                    if (item.Contacts_AccId && this.members.indexOf(item.Contacts_AccId) === -1) {
                        arr.push(item);
                        // item.disable = true;
                    }
                });
                this.checkedWaitMembers = arr;
            } else {
                this.checkedWaitMembers = [];
            }
            // this.checkedWaitMembers = this.checkWaitAll ? this.waitMembersData : [];
        },
        handleCheckedWaitChange(value) {
            const checkedCount = value.length;
            this.checkWaitAll = checkedCount === this.waitMembersData.length;
        },
        /* 已选列表选中事件 */
        handleChosedAllChange() {
            this.checkedChosedMembers = this.checkChosedAll ? this.chosedMembersData : [];
        },
        handleCheckedChosedChange() {
            const checkedCount = this.checkedChosedMembers.length;
            this.checkChosedAll = checkedCount === this.chosedMembersData.length;
        },
        // 向右选取
        transRight() {
            const newArr = [...this.chosedMembersData, ...this.checkedWaitMembers];
            this.chosedMembersData = Array.from(new Set(newArr));
            this.checkWaitAll = false;
            this.chosedMembersData.map(item => {
                this.waitMembersData.filter(v => {
                    if (v.User_Id === item.User_Id) {
                        v.disable = true;
                    }
                });
            });
            this.checkedWaitMembers = [];
            // this.waitMembersData = [];
        },
        // 删除已选人员
        delChosed() {
            const [ ...originalArr] = this.chosedMembersData;
            const [ ...delArr] = this.checkedChosedMembers;
            delArr.map(v => {
                const id = v.User_Id;
                originalArr.splice(originalArr.findIndex(item => item.User_Id === id), 1);
                this.waitMembersData.filter(item => {
                    if (v.User_Id === item.User_Id) {
                        item.is_friend = false;
                    }
                });
            });
            this.chosedMembersData = originalArr;
            this.checkChosedAll = false;
            this.checkedChosedMembers = [];
            const rows = this.waitMembersData;
            if (this.chosedMembersData.length > 0) {
                rows.filter(item => {
                    item.disable = false;
                });
                this.chosedMembersData.map(v => {
                    const id = v.User_Id;
                    rows.filter(item => {
                        if (item.User_Id === id) {
                            item.disable = true;
                        }
                    });
                });

            } else {
                rows.filter(item => {
                    item.disable = false;
                });
            }

            this.waitMembersData = rows;
        },
        // 确认选择人员
        submitFn() {
            const json = {
                type: this.type ? this.type : 'createTeam',
                data: this.chosedMembersData,
            };
            this.$emit('sure-members', json);
            // this.$emit('update-show', false);
        },
    },
    created() {
    },
    mounted() {},
    watch: {
        // 重新打开选人对话框-重置
        showDialogue(val) {
            if (val) {
                this.waitMembersData = []; // 待选-人员
                this.checkedWaitMembers = []; // 待选-已选人员
                this.checkWaitAll = false; // 待选-全选 
                this.chosedMembersData = []; // 已选-人员
                this.checkedChosedMembers = []; // 已选-已选人员
                this.checkChosedAll = false; // 已选-全选 
                this.searchName = ''; // 搜索条件
                this.currentIndex = 1;
            }
        },
    },
};
</script>
<style>
.choseMembers {
    overflow: hidden;
}
.choseMembers .el-tree-node__content{
    height: 30px;
}
.choseMembers .el-autocomplete{
    width: 100%;
}
.choseMembers .search{
    margin-bottom: .2rem;
}
.choseMembers .el-dialog__body{
    height: calc(100% - 130px);
}
.choseMembers .el-dialog__body .createTeamCon{
    height: calc(100% - 60px);
}
.choseMembers .el-tree-node__label{
    font-size: .14rem;
}
.choseMembers .el-checkbox .el-checkbox__label{
    font-size: .14rem;
}
.choseMembers .el-button{
    font-size: .14rem;
    padding: .12rem .20rem;
}
</style>