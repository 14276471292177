<template>
    <div class="">
        <div class="emoji-content">
            <div class="cnt">
                <span
                    class="emoji-item"
                    :class="{'pinup-item':item.type==='pinup'}"
                    v-for="(item) in currEmoji.list"
                    @click.stop="selectEmoji(item)"
                    :key="item.file"
                >
                    <img :src="item.img">
                </span>
            </div>
        </div>
        <div class="emoji-channel">
            <span
                class="emoji-album"
                :class="{active: item.name==currAlbum}"
                v-for="(item) in emoji"
                @click.stop="selectAlbum(item)"
                :key="item.file"
            >
                <img :src="item.album">
            </span>
            <template>
                <span
                    class="emoji-album"
                    :class="{active: item.name==currAlbum}"
                    v-for="(item) in pinup"
                    @click.stop="selectAlbum(item)"
                    :key="item.file"
                >
                    <img :src="item.album">
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import emojiObj from '@/common/NIM/configs/emoji';

function genEmojiList(type, emojiList) {
    const result = {};
    for (const name in emojiList) {
        const emojiMap = emojiList[name];
        const list = [];
        for (const key in emojiMap) {
            list.push({
                type,
                name,
                key,
                img: emojiMap[key].img,
            });
        }
        if (list.length > 0) {
            result[name] = {
                type,
                name,
                list,
                album: list[0].img,
            };
        }
    }
    return result;
}

export default {
    props: {
        type: String,
        scene: String,
        to: String,
    },
    data() {
        return {
            currType: 'emoji',
            currAlbum: 'emoji',
        };
    },
    computed: {
        emoji() {
            return genEmojiList('emoji', emojiObj.emojiList);
        },
        pinup() {
            return genEmojiList('pinup', emojiObj.pinupList);
        },
        currEmoji() {
            if (this.currType === 'emoji') {
                return this.emoji[this.currAlbum];
            } if (this.currType === 'pinup') {
                return this.pinup[this.currAlbum];
            }
            return [];
        },
    },
    created() {},
    methods: {
        selectAlbum(album) {
            this.currType = album.type;
            this.currAlbum = album.name;
        },
        selectEmoji(emoji) {
            if (this.currType === 'emoji') {
                // 由触发父组件事件，增加表情文案
                this.$emit('add-emoji', emoji.key);
            } else if (this.currType === 'pinup') {
                // if (this.type === 'session') {
                window.$globalHub.$store.dispatch('sendMsg', {
                    type: 'custom',
                    scene: this.scene,
                    to: this.to,
                    pushContent: '[贴图表情]',
                    content: {
                        type: 3,
                        data: {
                            catalog: this.currAlbum,
                            chartlet: emoji.key,
                        },
                    },
                });
                // } else if (this.type === 'chatroom') {
                //     window.$globalHub.$store.dispatch('sendChatroomMsg', {
                //         type: 'custom',
                //         pushContent: '[贴图表情]',
                //         content: {
                //             type: 3,
                //             data: {
                //                 catalog: this.currAlbum,
                //                 chartlet: emoji.key,
                //             },
                //         },
                //     });
                // }
                this.$emit('hide-emoji');
            }
        },
    },
};
</script>

<style lang="stylus" type="text/css">
</style>
